import { useState, useEffect, useCallback, useRef } from 'react';
import {
  Button,
  Image,
} from 'react-bootstrap';
import velocyExpress from './velocy-express.png';
import { connect } from 'react-redux';
import { gql, useMutation } from '@apollo/client';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faLock } from "@fortawesome/free-solid-svg-icons";
import { LOGIN, loginSuccess, loginFail } from '../actions';
import './Login.css';

const withIgnoredEvent = action => _ => {
  /* _.preventDefault(); */
  action(_);
}

const loginMutation = gql`
  mutation login($username: String!, $password: String!) {
    login(username: $username, password: $password)
  }
`;

const Login = ({ dispatch }) => {
  const [ user, _setUser ] = useState({
    username: '',
    password: '',
  });

  const setUser = withIgnoredEvent(u => _setUser({ ...user, username: u.target.value }));
  const setPassword = withIgnoredEvent(u => _setUser({ ...user, password: u.target.value }));
  const login = withIgnoredEvent(ev => {
    dispatch({ type: LOGIN, user });
    ev.preventDefault();
    _login({ variables: user });
  });

  const [_login, { loading, error }] = useMutation(loginMutation, {
    onError: e => {
      dispatch(loginFail(e.message));
    },
    onCompleted: ({ login }) => dispatch(loginSuccess(login)),
  });

  return (
    <div className="login-screen">
      <Image alt="Velocy Express" src={velocyExpress} className="logo" />
      <form
        onSubmit={login}
        className="login-container"
      >
        <Input
          id="username"
          type="text"
          label="Usuario"
          onChange={setUser}
          value={user.username}
          icon={faUser}
        />
        <Input
          id="password"
          type="password"
          label="Contraseña"
          onChange={setPassword}
          value={user.password}
          icon={faLock}
        />
        {error && <p className="error">{error.message}</p>}
        {!loading && (
          <Button variant="primary" type="submit" onClick={login}>
            Iniciar sesión
          </Button>)}
      </form>
    </div>
  )
};

const stopPropagation = ev => {
  ev.preventDefault();
  ev.stopPropagation();
  ev.nativeEvent.stopImmediatePropagation();
}

const isEmpty = value => (!value || value === '');
const getLabelClasses = value => (isEmpty(value) ? 'pristine' : '');

const Input = ({ type, icon, id, label, onChange, value }) => {
  const [labelClasses, setLabelClasses] = useState('');
  const input = useRef<HTMLInputElement>(null);

  useEffect(() => setLabelClasses(getLabelClasses(value)), [ value ]);
  const onMouseDown = useCallback(event => {
    stopPropagation(event);
    input.current?.focus();
    if (isEmpty(value)) {
      setLabelClasses('');
    }
  }, [value]);
  const onFocus = useCallback(event => {
    stopPropagation(event);
    if (isEmpty(value)) {
      setLabelClasses('');
    }
  }, [value]);
  const onBlur = useCallback(event => {
    stopPropagation(event);
    if (isEmpty(value)) {
       setLabelClasses('pristine');
    }
  }, [value]);

  return (
    <div className="input-container" onFocus={onFocus} onBlur={onBlur} onMouseDown={onMouseDown}>
      {icon && <FontAwesomeIcon icon={icon} className="icon" />}
      <input ref={input} type={type} id={id} onChange={onChange} value={value}></input>
      <label label-for={id} className={labelClasses}>{label}</label>
    </div>
  );
}

export default connect()(Login);
