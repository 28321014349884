import {
  Navbar,
  Nav,
  Button,
  Form,
  FormControl,
  Image,
} from 'react-bootstrap';
import velocyExpress from '../screens/velocy-express.png';

const image = {
  position: 'absolute',
  top: 0,
  left: 0,
  height: '9vh',
};

const Header = () => (
  <Navbar expand="lg" style={{ backgroundColor: '#fcec03', height: '9vh' }}>
    <div style={{ width: 100, height: 80 }}></div>
    <Image src={velocyExpress} style={image}/>
    <Navbar.Brand href="/">Velocy Express</Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="mr-auto">
        <Nav.Link href="/">Inicio</Nav.Link>
        <Nav.Link href="/servicios">Servicios</Nav.Link>
        <Nav.Link href="#">Clientes</Nav.Link>
      </Nav>
      <Form inline>
        <FormControl type="text" placeholder="Buscar servicio" className="mr-sm-2" />
        <Button variant="outline-success">Buscar servicio</Button>
      </Form>
    </Navbar.Collapse>
  </Navbar>
);

export default Header;
