import { Fragment } from 'react';
import Header from './Header';

const Page = ({ children }) => (
  <Fragment>
    <Header />
    {children}
  </Fragment>
);

export default Page;
