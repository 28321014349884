import { GET_DIRECTION_SUCCESS } from '../actions';
import _ from 'lodash';

const default_state = [];

// // update or insert item to array
// const upsert = (arr, item) => {
//   const { exists, index } = exists(arr, item);
//   if (exists) {
//     arr.splice(index, 1, item);
//   }
// }
const upsert = function(arr, key, newval) {
    var match = _.find(arr, key);
    if(match){
        var index = _.indexOf(arr, _.find(arr, key));
        arr.splice(index, 1, newval);
    } else {
        arr.push(newval);
    }
};

export default function DirectionsReducer(state = default_state, action) {
  switch (action.type) {
    case GET_DIRECTION_SUCCESS:
      const service = action.service;
      const direction = {
        id: service.id,
        direction: action.direction,
      };
      const directions = _.cloneDeep(state);

      upsert(directions, { id: direction.id }, direction);
      return directions;

    default:
      return state;
  }
}
