import React from 'react'
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '100%'
};

const mazatlan = {
  lat: 23.2494,
  lng: -106.4111,
};

function Map({ children }) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyA3UUWW0JWjLd-xT6gUpXevX8v7k8JeEHI"
  })

  // const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    // const bounds = new window.google.maps.LatLngBounds();
    // map.fitBounds(bounds);
    // setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    // setMap(null)
  }, [])

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      defaultCenter={mazatlan}
      center={mazatlan}
      zoom={11.0}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {children}
    </GoogleMap>
  ) : <></>
}

export default React.memo(Map);
