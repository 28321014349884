import { useState, useCallback } from 'react';
import { Image } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import './User.scss';

function getRandomColor() {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

const User = ({ image, firstName, isSelected, onMouseEnter, onMouseLeave, id }) => {
  const [style] = useState({ backgroundColor: getRandomColor() });
  const _onMouseEnter = useCallback(event => onMouseEnter(id), [onMouseEnter, id]);
  const _onMouseLeave = useCallback(event => onMouseLeave(id), [onMouseLeave, id]);

  if (image) {
    return (
      <Image src={image} roundedCircle />
    );
  }

  const backgroundColor = getRandomColor();

  return (
    <div className="user-icon-container" data-title={firstName} onMouseEnter={_onMouseEnter} onMouseLeave={_onMouseLeave}>
      <div style={style} className="user-icon">
        <FontAwesomeIcon icon={faUser} />
      </div>
      <span className="first-name">{firstName}</span>
    </div>
  );
};

export default User;
