import React, { useState, useEffect } from 'react';
import { DirectionsService, DirectionsRenderer } from '@react-google-maps/api';
import { connect } from 'react-redux';
import { getDirectionSuccess, getDirectionFail } from '../actions';
import _ from 'lodash';

const exists = (arr, item) => {
  let index = _.findIndex(arr, item);
  return { exists: index >= 0, index };
}

const getOptions = service => ({
  destination: service.destination.coordinates,
  origin: service.origin.coordinates,
  travelMode: 'DRIVING',
});

const Direction: React.FC = ({ directions, dispatch, service }) => {
  const [options, setOptions] = useState(getOptions(service));

  const { exists: directionExists, index: direction } = exists(directions, { id: service.id });

  useEffect(() => {
    setOptions(getOptions(service));
  }, [ service ]);

  /* useEffect(() => {
*   const { destination, origin, travelMode, key } = options;
*   const API = 'https://maps.googleapis.com/maps/api/directions/json?';
*   const url = `${API}?origin=${origin}&destination=${destination}&travelMode=${travelMode}&key=${options.key}`;
*   const opt = { method: GET };
*   fetch(url, opt).then(console.log).catch(console.log);
* }, []);
 */

  const onReceiveDirection = React.useCallback(function callback(response) {
    console.log(response);
    if (response && response.status === 'OK') {
      dispatch(getDirectionSuccess(service, response))
    } else {
      dispatch(getDirectionFail(service, response))
    }
  }, [dispatch, service]);

  return (
    <React.Fragment>
      {!directionExists &&
        <DirectionsService
          callback={onReceiveDirection}
          options={options}
        />}
      {
        directionExists && (
          <DirectionsRenderer
            options={{
              directions: directions[direction].direction
            }}
          />
        )
      }
    </React.Fragment>
  );
}

const state = ({ directions }) => ({ directions });

export default connect(state)(Direction);
