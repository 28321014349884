import { push } from 'connected-react-router';
// Definiciones
// Cuando inicias sesion existen 2 posibilidades
export const LOGIN = 'LOGIN';
// Escribir las credenciales correctas permitira inciar sesion exitosamente
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
// De lo contrario fallara la solicitud
export const LOGIN_FAIL = 'LOGIN_FAIL';

// Cerrar la sesion es una accion que no puede fallar
export const LOGOUT = 'LOGOUT';

// Obtener los servicios (ordenes/envios)
export const GET_SERVICES = 'GET_SERVICES';
export const GET_SERVICES_SUCCESS = 'GET_SERVICES_SUCCESS';
export const GET_SERVICES_FAIL = 'GET_SERVICES_FAIL';

export const GET_DIRECTION = 'GET_DIRECTION';
export const GET_DIRECTION_SUCCESS = 'GET_DIRECTION_SUCCESS';
export const GET_DIRECTION_FAIL = 'GET_DIRECTION_FAIL';

export function loginSuccess (token) {
  return async function (dispatch) {
    dispatch({
      type: LOGIN_SUCCESS,
      token,
    });
    localStorage.setItem('token', token);
    dispatch(push('/services'));
  }
};

export const loginFail = (error) => {
  return (dispatch) => {
    dispatch({
      type: LOGIN_FAIL,
      error,
    });
    localStorage.removeItem('token');
  }
};

export const getServicesSuccess = (services) => {
  return function(dispatch) {
    // console.log('getServicesSuccess', services);
    dispatch({
      type: GET_SERVICES_SUCCESS,
      services,
    });
  }
};

export const getServicesFail = (error) => {
  return function(dispatch) {
    // console.log('getServicesFail', error);
    dispatch({
      type: GET_SERVICES_FAIL,
      error,
    });
  }
};

export const getDirectionSuccess = (service, direction) => {
  return function(dispatch) {
    dispatch({
      type: GET_DIRECTION_SUCCESS,
      service,
      direction,
    });
  }
};

export const getDirectionFail = (service, error) => {
  return function(dispatch) {
    // console.log('getDirectionFail', error);
    dispatch({
      type: GET_DIRECTION_FAIL,
      service,
      error,
    });
  }
};
