import 'bootstrap/dist/css/bootstrap.min.css';
import Login from './screens/Login';
import Servicios from './screens/Servicios';
import { connect } from 'react-redux';
import './App.css';

const App = ({ user }) => {
  const loggedIn = user && user.id;

  return (
    <div className="App">
      {loggedIn ? <Servicios user={user} /> : <Login />}
    </div>
  );
}

const state = (s, p) => ({
  user: s.user,
});

export default connect(state)(App);
