import { useEffect, useState, useCallback } from 'react';
import { Table } from 'react-bootstrap';
import './ServicesTable.scss';

const Service = (service) => {
  const {
    id,
    driver,
    origin,
    destination,
    date,
    cost,
    selected,
    selecting,
    onSelect: onMouseEnter,
    onLeave: onMouseLeave,
  } = service;

  const _onMouseEnter = useCallback(event => onMouseEnter(service), [onMouseEnter, service.id]);
  const _onMouseLeave = useCallback(event => onMouseLeave(service), [onMouseLeave, service.id]);

  return (
    <tr
      onMouseEnter={_onMouseEnter}
      onMouseLeave={_onMouseLeave}
      className={selecting ? (selected ? 'selected' : 'not-selected') : undefined}
    >
      <td>{id}</td>
      <td>{driver?.firstName}</td>
      <td>{origin.address}</td>
      <td>{destination.address}</td>
      <td>{date}</td>
      <td>${cost}</td>
    </tr>
  );
};

const ServicesTable = ({ services, selectedUser, onSelect, onLeave }) => (
  <Table striped bordered hover className="services-table">
    <thead>
      <tr>
        <th># ID</th>
        <th>Repartidor</th>
        <th>Ubicacion</th>
        <th>Destino</th>
        <th>Fecha</th>
        <th>Costo</th>
      </tr>
    </thead>
    <tbody>
      {services.map(service =>
        <Service
          {...service}
          key={service.id}
          selected={selectedUser === service.driver?.id }
          selecting={!!selectedUser}
          onSelect={onSelect}
          onLeave={onLeave}
        />)}
    </tbody>
  </Table>
);

export default ServicesTable;
