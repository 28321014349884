import { useState, useCallback, useEffect } from 'react';
import { Button } from 'react-bootstrap';
// import faker from 'faker';
import Page from '../components/Page';
import Map from '../components/Map';
import User from '../components/User';
import { getServicesSuccess } from '../actions';
import { connect } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import ServicesTable from '../components/ServicesTable';
import { getTrips } from '../queries';
import './Servicios.scss';
import Direction from '../components/Direction';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import spinner from './spinner.png';

/* const Users = () => (
 *   <Row>
 *     <Col>
 *     </Col>
 *   </Row>
 * ); */

const Servicios = ({ services, markers, clients, drivers, getServices, dispatch }) => {
  const [loadTrips, { loading, error, data }] = useLazyQuery(getTrips, {
    onError: e => {
      console.log(e);
    },
    onCompleted: ({ getTrips }) => dispatch(getServicesSuccess(getTrips)),
  });

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [status, setStatus] = useState(null);

  useEffect(() => loadTrips({ variables: {
    startDate,
    endDate,
    status,
  }}), [ loadTrips, startDate, endDate, status ]);

  const onMouseEnter = setSelectedUser
  const onMouseLeave = () => setSelectedUser(null);
  const service = services?.length > 0 && services[0];
  const onSelectService = setSelectedService;
  const onLeaveService = () => setSelectedService(null);
  const clearStartDate = () => setStartDate(null);
  const clearEndDate = () => setEndDate(null);
  const onSelectStatus = useCallback(ev => setStatus(ev.target.value), []);

  return (
    <Page>
    <div className="services">
      <div className="services list">
        <div className="services list filters">
          <div className="filter">
            <span>Fecha inicial</span>
            <DatePicker selected={startDate} onChange={date => setStartDate(date)} />
            {startDate && <DeleteButton onClick={clearStartDate} />}
          </div>
          <div className="filter">
            <span>Fecha final</span>
            <DatePicker selected={endDate} onChange={date => setEndDate(date)} />
            {endDate && <DeleteButton onClick={clearEndDate} />}
          </div>
          <div className="filter">
            <span>Status</span>
            <select onChange={onSelectStatus} value={status}>
              <option>Todos</option>
              <option value="PENDIENTE">Pendiente</option>
              <option value="EN_CURSO">En curso</option>
              <option value="FINALIZADO">Finalizado</option>
              <option value="CANCELADO">Cancelado</option>
            </select>
          </div>
        </div>
        <div className="services list users">
          {drivers.map(driver => <User {...driver} key={driver.id} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} />)}
        </div>
        <div className="services list table">
          <ServicesTable
            services={services}
            selectedUser={selectedUser}
            onSelect={onSelectService}
            onLeave={onLeaveService} />
        </div>
        {loading &&
          <div className="overlay">
            <div className="spinner"></div>
          </div>}
      </div>
      <div className="services-map">
        <Map>
          {selectedService && <Direction service={selectedService} />}
            {/* {service && <Direction service={service} />} */}
        </Map>
      </div>
    </div>
    </Page>
  );
};

const DeleteButton = ({ onClick }) => (
  <Button
    variant="link"
    className="delete"
    onClick={onClick}
  >
    <FontAwesomeIcon icon={faTrash} />
  </Button>
);

const state = ({ services: { services, markers, clients, drivers } }, p) => ({
  services, markers, clients, drivers,
});

export default connect(state)(Servicios);
