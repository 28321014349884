import { gql } from '@apollo/client';

const getTrips = gql`
  query getTrips($startDate: Date, $endDate: Date, $driver: Int, $status: TripStatus) {
    getTrips(startDate: $startDate, endDate: $endDate, driver: $driver, status: $status) {
      id
      driver {
        id
        firstName
      }
      client {
        id
        firstName
      }

      origin {
        address
        description
        coordinates {
          lat
          lng
        }
      }
      destination {
        address
        description
        coordinates {
          lat
          lng
        }
      }
      distance
      stops {
        address
        description
        coordinates {
          lat
          lng
        }
      }

      date

      status

      completedAt
      acceptedAt
      cancelledAt

      score
      cost
    }
  }
`;

export default getTrips;
