import { combineReducers } from 'redux';
import servicesReducer from './services';
import userReducer from './user';
import { connectRouter } from 'connected-react-router';
import DirectionsReducer from './directions';

const createRootReducer = (history) => combineReducers({
  user: userReducer,
  services: servicesReducer,
  router: connectRouter(history),
  directions: DirectionsReducer,
});

export default createRootReducer;
