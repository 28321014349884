import { useCallback } from 'react';
import { Route, Redirect } from 'react-router';
import { connect } from 'react-redux';

const AuthenticatedRoute = ({component: Component, user, ...rest}) => {
    /* const [state] = useState({ from: location }); */
  const renderRoute = useCallback((props) =>  {
    if (localStorage.getItem('token')) {
        /* if (user && user.id) { */
        return <Component {...props} />;
      }

      return (
        <Redirect
          to={{pathname: '/login', state: { from: props.location }}} />
      );
    /* }, [Component, user]); */
  }, [ Component ]);

  return (
    <Route {...rest} render={renderRoute} />
  );
}

const state = (s, p) => ({
  user: s.user,
  router: s.router,
});

export default connect(state)(AuthenticatedRoute);
