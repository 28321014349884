import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAIL
} from '../actions';

const default_state = {
  id: null,
  user: null,
  name: null,
  role: null,
  roleDesc: null,
  loginError: null,
  token: null,
};

export default function UserReducer(state = default_state, action) {
  switch (action.type) {

    case LOGIN:
      return state;

    case LOGIN_SUCCESS:
      return {
        ...state,
        token: action.token,
        // id: action.user.id_usuario,
        // user: action.user.usuario,
        // name: action.user.nombre_usuario,
        // role: action.user.rol,
        // roleDesc: action.user.descripcion_rol,
      };

    case LOGIN_FAIL:
      return { ...state, loginError: action.error };

    default:
      return state;
  }
}
