import { GET_SERVICES_SUCCESS } from '../actions';
import _ from 'lodash';

const default_state = {
  services: [],
  markers: [],
  drivers: [],
  clients: [],
};

const getDriver = service => service.driver;
const getClient = service => service.client;
const getMarker = service => ({
  id: service.id,
  location: {
    lat: Number(service.origen_latitud),
    lng: Number(service.origen_longitud),
  },
});

const nonNull = _.compact;
const unique = arr => _.uniqBy(arr, 'id');
// const purge = _.flowRight(unique, nonNull);
const purge = arr => unique(nonNull(arr));

export default function ServicesReducer(state = default_state, action) {
  switch (action.type) {
    case GET_SERVICES_SUCCESS:
      const services = action.services;
      const markers = [];
      const drivers = [];
      const clients = [];

      services.forEach(service => {
        const marker = getMarker(service);
        const driver = getDriver(service);
        const client = getClient(service);

        markers.push(marker);
        drivers.push(driver);
        clients.push(client);
      });

      return {
        services: services,
        markers: purge(markers),
        drivers: purge(drivers),
        clients: purge(clients),
      };

    default:
      return state;
  }
}
